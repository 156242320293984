<template>
	<div>
		<HeaderPhone topTitle="业务领域" style="position: fixed;"></HeaderPhone>
		<div style="height: 50px;"></div>
		<div class="banner"></div>
		<div style="padding: 50px 25px 0">
			<div v-for="(item,index) in list" :key="index" style="margin-bottom: 50px;">
				<el-image :src="item.src" class="imgAdaptive" style="margin-bottom: 20px;"></el-image>
				<div style="line-height: 22px;">
					{{item.con}}
				</div>
			</div>
		</div>
		<FooterPhone></FooterPhone>
	</div>
</template>

<script>
import HeaderPhone from "@/components/headerPhone";
import FooterPhone from "@/components/footerPhone";
export default {
	data() {
		return {
			list: [
				{ src: require('../static/imagesPhone/business/Group 102.jpg'), con: '司“资本支持+产业赋能”的投资模式多次被成功验证，为具备潜力的初创企业和团队提供资金支持和产业资源导入，通过严格的风险管理和规范化运营，实现被投企业快速发展和估值提升,主要方向为数字信息应用平台及软硬件、人工智能、网络安全、运维安全、数字影视及传媒和新能源等。。'},
				{ src: require('../static/imagesPhone/business/Group 103.jpg'), con: '网宇科创在东莞和厦门两地运营定制化的商业加速器项目，为具有创新潜力的初创企业提供孵化服务，包括提供办公场地和物业支持、配备相应的软硬件服务、政策补贴、业务培训和专家指导等，帮助初创企业加速发展并在市场中获得竞争优势。'},
				{ src: require('../static/imagesPhone/business/Group 106.jpg'), con: '经过近十年的发展，网宇科创与业内创业导师和行业专家深入交流、合作，共同创造出先进的研究成果并积累了丰富的创业实践经验，能够为被投企业提供宝贵的创业指导、建议和战略规划。'},
				{ src: require('../static/imagesPhone/business/Group 115.jpg'), con: '公司发展过程中，长期与其他各个投资机构、产业基金、券商、银行、律所、会计师事务所、咨询公司和猎头公司等深入合作，推介上述三方机构为被投企业提供增值服务，在企业战略规划、市场营销、整合融资、人才招聘、上市辅导等方面提供配套的支持，使初创企业能够专心攻克技术、优化产品，无后顾之忧。'},
				{ src: require('../static/imagesPhone/business/Group 116.jpg'), con: '网宇科创会推介内部已被投资孵化的企业之间进行上下游资源对接、交流技术创新、共享研究成果和讨论行业发展与机会等，被投企业根据自身发展各取所需，在网宇科创的引导和内部孵化企业的产业赋能之下，逐步探索出稳健可持续的发展模式。'},
			],
		}
	},
	components: {
		HeaderPhone,
		FooterPhone,
	},
	methods: {
		
	},
}
</script>

<style scoped>
.banner {
	height: 38vh;
	background: url("../static/imagesPhone/business/top123.jpg") no-repeat;
	background-size: cover;
	background-position: center center;
}
</style>